.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bold-text {
  color:#650E3D;
  font-weight: bold;
}
.size-20 {
  font-size: 20px;
}
.size-36 {
  font-size: 36px;
}
.size_10{
  font-size: 10px;
}
.colored-text{
  color: #650E3D;
}
.image-fit-cover{
  object-fit: cover;
  object-position: center;
}
.flex-centered{
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-flex{
  display: flex;
  flex-direction: column;
}

.vertical-flex-centered{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.weight-500{
  font-weight:500;
}
.weight-700{
  font-weight:700;
}
.small_text_column{
  font-size: 10px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.rmdp-arrow{
  border-top-color: #650E3D !important;
  border-right-color: #650E3D !important;
  border-bottom-color: #650E3D !important;
  border-left-color: #650E3D !important;
}
.rmdp-arrow-container:hover{
  background: rgba(102, 2, 60, 0.3) !important;
}

.rmdp-header-values{
  color:#650E3D !important;
}
.rmdp-week-day{
  color:#BCBCBC !important;
}

.rmdp-selected > span {
  background: rgba(102, 2, 60, 0.3) !important;
  color: #000000 !important;
  box-shadow: none !important;
  /* mix-blend-mode: multiply; */
}

.swal2-container{
  z-index: 999999 !important;
  position: fixed !important;
}
.rbc-btn-group button{
  border-color: #650E3D !important;
  color: #650E3D !important;
  text-transform: uppercase !important;
}
.rbc-btn-group button:hover{
  cursor:  pointer !important;
  color: #ffffff !important;
  background-color: #650E3D !important;
}
.rbc-btn-group .rbc-active{
  background-color: #650E3D !important;
  color: white !important;
}
/* .rbc-btn-group button:first-child{
  background-color: #650E3D !important;
  color: #ffffff !important;
  margin-right: 20px !important;
  margin-left: 20px !important;
  border-radius: 20px !important;
  width: 100px !important;
}

.rbc-btn-group button:nth-child(2){
  color: #650E3D !important;
  margin-right: 20px !important;
  border-radius: 20px !important;
  border-color:#650E3D !important;
  width: 100px !important;
}
.rbc-btn-group button:nth-child(2)::before{
  content: " < " !important;
  margin-left: -10px;
}

.rbc-btn-group button:nth-child(3){
  color: #650E3D !important;
  margin-right: 20px !important;
  border-radius: 20px !important;
  border-color:#650E3D !important;
  width: 100px !important;
}
.rbc-btn-group button:nth-child(3)::after{
  content: ' > '!important;
} */
.rbc-toolbar-label{
  color:#650E3D !important;
  font-size: 20px !important;
}
.rbc-label{
  color: #650E3D !important;
}
.rbc-event{
  background-color: #650E3D !important;
}
.rbc-button-link{
  color: #650E3D !important;
  text-transform: uppercase;
}
.rbc-today{
  background-color: #FCEDF7 !important;
  /* border-color: #E2D1DA !important; */
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}