.container {
    /* bordeR:  1px solid red; */
    height: 95%;
    width: 70%;
}

.text {
    /* border: 1px solid black; */
    text-align: left;
    color: #650E3D;
    /* font-family: Poppins; */
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 48px;
}

.line_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.left_line,
.right_line {
    flex-grow: 1;
    height: 1px;
    background-color: #650E3D;
}

.centered_text {
    padding: 0 10px;
    color:#650E3D;
    text-align: center;
}
.signupForm{
    /* height: 200px; */
}
.small_text{
    height: 100%;
    width: 100%;
    text-align: left;
    font-size: 10px;
    line-height: 15px;
    color: #650E3D;
    display: flex;
    align-items: center;
    justify-content: center;
}
.medium_text{
    height: 100%;
    width: 100%;
    text-align: left;
    font-size: 14px;
    line-height: 15px;
    color: #650E3D;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    /* bordeR:1px solid black; */
}